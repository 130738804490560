import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";

interface StaticInfo {
  readonly apiUrl: () => string;
  readonly country: Country;
  readonly segment: Segment;
  readonly kameleoon: KameleoonEnvironment;
  readonly androidInAppBrowser: boolean;
}

const StaticInfoContext = createContext<StaticInfo>(null as unknown as StaticInfo);

interface StaticInfoProviderProps {
  readonly children: ReactNode;
  readonly apiUrl: () => string;
  readonly country: Country;
  readonly segment: Segment;
  readonly kameleoon: KameleoonEnvironment;
  readonly androidInAppBrowser?: boolean;
}

const StaticInfoProvider: FC<StaticInfoProviderProps> = ({
  apiUrl,
  country,
  segment,
  kameleoon,
  children,
  androidInAppBrowser = false,
}) => {
  const value = useMemo(
    () => ({ apiUrl, country, segment, kameleoon, androidInAppBrowser }),
    [androidInAppBrowser, apiUrl, country, kameleoon, segment],
  );

  return <StaticInfoContext.Provider value={value}>{children}</StaticInfoContext.Provider>;
};

const useStaticInfo = () => {
  const staticInfo = useContext(StaticInfoContext);

  invariant(
    staticInfo,
    "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.",
  );

  return staticInfo;
};

export { StaticInfoProvider, useStaticInfo };
